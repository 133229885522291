import * as R from 'ramda';
import { CLIENT_TYPE_BLOOM, CLIENT_TYPE_SWORD, D2C_OPTIONS } from '@/scripts/constants';
import appConfigs from '@/config';

const NO_VALUES = [
  {
    optLabel: '-',
    optValue: null,
  },
];

export const dateFormatOptions = [
  {
    text: 'yyyy-mm-dd',
    value: 'yyyy-mm-dd',
  },
  {
    text: 'mm-dd-yyyy',
    value: 'mm-dd-yyyy',
  },
  {
    text: 'dd-mm-yyyy',
    value: 'dd-mm-yyyy',
  },
  {
    text: 'yyyy-dd-mm',
    value: 'yyyy-dd-mm',
  },
];

export const timeFormatOptions = [
  {
    text: '12h',
    value: '12',
  },
  {
    text: '24h',
    value: '24',
  },
];

export const timezoneOptions = [
  {
    text: 'US',
    value: 'us',
  },
  {
    text: 'GMT',
    value: 'gmt',
  },
];

export const professionalsLanguages = R.compose(
  R.values,
  R.pick(R.__, {
    en: { text: 'English', value: 'en' },
    pt: { text: 'Portuguese', value: 'pt' },
    es: { text: 'Spanish', value: 'es' },
    fr: { text: 'French', value: 'fr' },
  }),
  R.split(','),
  R.defaultTo('en'),
)(appConfigs.get('VUE_APP_PROFESSIONAL_LANGUAGES'));

export const whiteListOptions = [
  {
    value: 'first_name',
    label: 'First name',
  },
  {
    value: 'last_name',
    label: 'Last name',
  },
  {
    value: 'gender',
    label: 'Gender',
  },
  {
    value: 'date_of_birth',
    label: 'Date of birth',
  },
  {
    value: 'email',
    label: 'Email',
  },
  {
    value: 'member_id',
    label: 'Member ID',
  },
];

export const clientToggleOptions = [
  {
    value: 'kit_shipped_only_after_video_call',
    label: 'Kit shipped only after video call',
    default: false,
    disabled: false,
    tooltip: 'If enabled the Kit will only be shipped after video call',
    client_type: [ CLIENT_TYPE_SWORD, CLIENT_TYPE_BLOOM ],
  },
  {
    value: 'allow_preventive_enrollment',
    label: 'Allow enrollments in preventive program',
    default: false,
    disabled: false,
    tooltip: 'If disabled the member won\'t have the option to enroll in preventive program',
    client_type: [ CLIENT_TYPE_SWORD ],
  },
  {
    value: 'care_coordinator_enabled',
    label: 'Client has Care Coordinator',
    default: false,
    disabled: false,
    tooltip: 'Only available when Subscriber and Dependent eligibility check is not selected',
    client_type: [ CLIENT_TYPE_SWORD, CLIENT_TYPE_BLOOM ],
  },
  {
    value: 'require_subscriber_dependent_information',
    label: 'Subscriber and Dependent eligibility check',
    default: false,
    disabled: false,
    tooltip: 'Only available when Client has Care Coordinator is not selected',
    client_type: [ CLIENT_TYPE_SWORD, CLIENT_TYPE_BLOOM ],
  },
  {
    value: 'show_relationship_field_on_onboarding',
    label: 'Shows Relationship field for dependents\' onboarding (mandatory)',
    default: false,
    disabled: false,
    client_type: [ CLIENT_TYPE_SWORD, CLIENT_TYPE_BLOOM ],
  },
  {
    value: 'show_payers_list',
    label: 'Shows payers list',
    default: false,
    disabled: false,
    client_type: [ CLIENT_TYPE_SWORD, CLIENT_TYPE_BLOOM ],
  },
  {
    value: 'required_member_id_field',
    label: 'Member id field is required',
    default: false,
    disabled: false,
    client_type: [ CLIENT_TYPE_SWORD, CLIENT_TYPE_BLOOM ],
  },
  {
    value: 'show_member_id_field',
    label: 'Shows member id field',
    default: false,
    disabled: false,
    client_type: [ CLIENT_TYPE_SWORD, CLIENT_TYPE_BLOOM ],
  },
  {
    value: 'send_drop_off_emails',
    label: 'Send abandon / drop off emails',
    default: false,
    disabled: false,
    client_type: [ CLIENT_TYPE_SWORD, CLIENT_TYPE_BLOOM ],
  },
  {
    value: 'show_how_long_experiencing_pain_field',
    label: 'Shows how long experiencing pain field',
    default: true,
    disabled: false,
    client_type: [ CLIENT_TYPE_SWORD ],
  },
  {
    value: 'bypass_pt_regulation_status',
    label: 'Bypass PT regulation status check',
    default: false,
    disabled: false,
    client_type: [ CLIENT_TYPE_SWORD, CLIENT_TYPE_BLOOM ],
  },
  {
    value: 'bypass_clinical_referral_rules',
    label: 'Bypass clinical referral rules',
    default: false,
    disabled: false,
    client_type: [ CLIENT_TYPE_SWORD, CLIENT_TYPE_BLOOM ],
  },
  {
    value: 'allow_minor_enrollments',
    label: 'Allow the registration of underage members (using 18 years as start of adult age)',
    default: true,
    disabled: false,
    client_type: [ CLIENT_TYPE_SWORD ],
  },
  {
    value: 'wheel_integration',
    label: 'Disable integration with Wheel API (Creates referral consult after video call)',
    default: false,
    disabled: false,
    client_type: [ CLIENT_TYPE_SWORD ],
  },
  {
    value: 'enable_sendbird_video_call',
    label: 'Enable video calls using sendbird',
    default: false,
    disabled: true,
    tooltip: 'Once this toggle is enabled it cannot be disabled again for this client',
    client_type: [ CLIENT_TYPE_SWORD ],
  },
  {
    value: 'preventive_chat_enabled',
    label: 'Enables chat when in a preventive program',
    default: true,
    disabled: false,
    client_type: [ CLIENT_TYPE_SWORD ],
  },
  {
    value: 'conservative_mode',
    label: 'Enables conservative mode',
    default: false,
    disabled: false,
    tooltip: 'Gender will only show male/female',
    client_type: [ CLIENT_TYPE_SWORD ],
  },
  {
    value: 'can_skip_video_call',
    label: 'Allows members to skip the video call',
    default: false,
    disabled: false,
    client_type: [ CLIENT_TYPE_SWORD, CLIENT_TYPE_BLOOM ],
  },
  {
    value: 'show_on_marketing_pages',
    label: 'Show on marketing pages (in.swordhealth.com, join, at, ...)',
    default: false,
    disabled: false,
    client_type: [ CLIENT_TYPE_SWORD ],
  },
  {
    value: 'show_vaginal_anatomy_question_on_onboarding',
    label: 'Shows vaginal anatomy question on onboarding eligibility page',
    default: true,
    disabled: false,
    client_type: [ CLIENT_TYPE_BLOOM ],
  },
  {
    value: 'allow_no_pod_sessions',
    label: 'Allow no-pod sessions',
    default: false,
    disabled: false,
    client_type: [ CLIENT_TYPE_BLOOM ],
  },
  {
    value: 'show_support_mode',
    label: 'Shows support mode',
    default: false,
    disabled: false,
    client_type: [ CLIENT_TYPE_SWORD ],
  },
  {
    value: 'can_upgrade_program',
    label: 'Can upgrade program',
    default: true,
    disabled: false,
    client_type: [ CLIENT_TYPE_SWORD ],
  },
  {
    value: 'enable_health_integration_exchange',
    label: 'Enable Health Integration Exchange (HIE)',
    default: true,
    disabled: false,
    client_type: [ CLIENT_TYPE_SWORD, CLIENT_TYPE_BLOOM ],
    regions: [ 'us', 'staging', 'clinical' ],
  },
  {
    value: 'show_hub_tab',
    label: 'Show hub tab for non-preventive programs',
    default: false,
    disabled: false,
    client_type: [ CLIENT_TYPE_SWORD, CLIENT_TYPE_BLOOM ],
  },
  {
    value: 'show_fake_slots',
    label: 'Shows fake slots',
    default: false,
    disabled: true,
    client_type: [ CLIENT_TYPE_SWORD ],
  },
];

export const beneficiaryTypes = [
  { value: 'CHD', label: 'Children only' },
  { value: 'DEP', label: 'Dependents only' },
  { value: 'ECH', label: 'Employee and children' },
  { value: 'EMP', label: 'Employee only' },
  { value: 'ESP', label: 'Employee and spouse' },
  { value: 'FAM', label: 'Family' },
  { value: 'IND', label: 'Individual' },
  { value: 'SPC', label: 'Spouse and children' },
  { value: 'SPO', label: 'Spouse only' },
];

export const eligibilityFileSeparatorOptions = [
  { value: ','.charCodeAt(0), text: ',' },
  { value: ';'.charCodeAt(0), text: ';' },
  { value: '~'.charCodeAt(0), text: '~' },
  { value: '|'.charCodeAt(0), text: '|' },
  { value: '\t'.charCodeAt(0), text: '\\t' },
];

export const claimsFileSeparatorOptions = [
  { value: ','.charCodeAt(0), text: ',' },
  { value: ';'.charCodeAt(0), text: ';' },
  { value: '|'.charCodeAt(0), text: '|' },
  { value: '\t'.charCodeAt(0), text: '\\t' },
];

export const eligibilityRelationshipOptions = [
  { value: '18', text: 'Self' },
  { value: '01', text: 'Spouse' },
  { value: '19', text: 'Child' },
  { value: '20', text: 'Employee' },
  { value: '21', text: 'Unknown' },
  { value: '53', text: 'Life Partner' },
  { value: 'G8', text: 'Other Relationship' },
];

export const eligibilityGenderOptions = [
  { value: 'M', text: 'Male' },
  { value: 'F', text: 'Female' },
  { value: 'I', text: 'Non-binary' },
  { value: 'A', text: 'Prefer not to specify' },
  { value: 'U', text: 'Other' },
];

export const eligibilityServiceTypeOptions = [
  { value: 'P', text: 'PT Provider' },
  { value: 'W', text: 'Wellness' },
];

export const institutionIndustryBroadOptions = [
  {
    optLabel: 'Government / Public Sector',
    optValue: 'government_public_sector',
  },
  {
    optLabel: 'Retail',
    optValue: 'retail',
  },
  {
    optLabel: 'Healthcare',
    optValue: 'healthcare',
  },
  {
    optLabel: 'Professional / White Collar',
    optValue: 'professional_white_collar',
  },
  {
    optLabel: 'Physical / Blue Collar',
    optValue: 'physical_blue_collar',
  },
  {
    optLabel: 'Mixed White / Blue',
    optValue: 'mixed_white_blue',
  },
  {
    optLabel: 'Agriculture',
    optValue: 'agriculture',
  },
  {
    optLabel: 'Business Services',
    optValue: 'business_services',
  },
  {
    optLabel: 'Construction',
    optValue: 'construction',
  },
  {
    optLabel: 'Consumer Services',
    optValue: 'consumer_services',
  },
  {
    optLabel: 'Education',
    optValue: 'education',
  },
  {
    optLabel: 'Energy, Utilities & Waste',
    optValue: 'energy_utilities_waste',
  },
  {
    optLabel: 'Finance',
    optValue: 'finance',
  },
  {
    optLabel: 'Government',
    optValue: 'government',
  },
  {
    optLabel: 'Healthcare Services',
    optValue: 'healthcare_services',
  },
  {
    optLabel: 'Holding Companies & Conglomerates',
    optValue: 'holding_companies_conglomerates',
  },
  {
    optLabel: 'Hospitality',
    optValue: 'hospitality',
  },
  {
    optLabel: 'Hospitals & Physicians Clinics',
    optValue: 'hospitals_physicians_clinics',
  },
  {
    optLabel: 'Insurance',
    optValue: 'insurance',
  },
  {
    optLabel: 'Law Firms & Legal Services',
    optValue: 'law_firms_legal_services',
  },
  {
    optLabel: 'Manufacturing',
    optValue: 'manufacturing',
  },
  {
    optLabel: 'Media & Internet',
    optValue: 'media_internet',
  },
  {
    optLabel: 'Minerals & Mining',
    optValue: 'minerals_mining',
  },
  {
    optLabel: 'Organizations',
    optValue: 'organizations',
  },
  {
    optLabel: 'Real Estate',
    optValue: 'real_estate',
  },
  {
    optLabel: 'Software',
    optValue: 'software',
  },
  {
    optLabel: 'Telecommunications',
    optValue: 'telecommunications',
  },
  {
    optLabel: 'Transportation',
    optValue: 'transportation',
  },
];

export const institutionIndustrySubOptions = {
  government_public_sector: [
    {
      optLabel: 'Education',
      optValue: 'education',
    },
    {
      optLabel: 'Government',
      optValue: 'government',
    },
    {
      optLabel: 'Labor Union Fund',
      optValue: 'labor_union_fund',
    },
    {
      optLabel: 'Non Profit',
      optValue: 'non_profit',
    },
  ],
  retail: [
    {
      optLabel: 'Food & Beverage',
      optValue: 'food_and_beverage',
    },
    {
      optLabel: 'Restaurants',
      optValue: 'restaurants',
    },
    {
      optLabel: 'Hospitality',
      optValue: 'hospitality',
    },
    {
      optLabel: 'Rentals',
      optValue: 'rentals',
    },
    {
      optLabel: 'Department Stores',
      optValue: 'department_stores',
    },
    {
      optLabel: 'Automotive',
      optValue: 'automotive',
    },
    {
      optLabel: 'Sporting & Recreational Equipment Retail',
      optValue: 'sporting_recreational_retail',
    },
    {
      optLabel: 'Convenience Stores, Gas Stations & Liquor Stores',
      optValue: 'convenience_stores_gas_stations_liquor_stores',
    },
    {
      optLabel: 'Automobile Dealers',
      optValue: 'automobile_dealers',
    },
    {
      optLabel: 'Vitamins, Supplements & Health Stores',
      optValue: 'vitamins_supplements_and_health_stores',
    },
    {
      optLabel: 'Drug Stores & Pharmacies',
      optValue: 'drug_stores_and_pharmacies',
    },
    {
      optLabel: 'Department Stores, Shopping Centers & Superstores',
      optValue: 'department_stores_shopping_centers_superstores',
    },
    {
      optLabel: 'Automobile Parts Stores',
      optValue: 'automobile_parts_stores',
    },
    {
      optLabel: 'Furniture',
      optValue: 'furniture',
    },
    {
      optLabel: 'Record, Video & Book Stores',
      optValue: 'record_video_and_book_stores',
    },
    {
      optLabel: 'Toys & Games',
      optValue: 'toys_games',
    },
    {
      optLabel: 'Home Improvement & Hardware Retail',
      optValue: 'home_improvement_and_hardware_retail',
    },
    {
      optLabel: 'Office Products Retail & Distribution',
      optValue: 'office_products_retail_and_distribution',
    },
    {
      optLabel: 'Pet Products',
      optValue: 'pet_products',
    },
    {
      optLabel: 'Jewelry & Watch Retail',
      optValue: 'jewelry_and_watch_retail',
    },
    {
      optLabel: 'Grocery Retail',
      optValue: 'grocery_retail',
    },
    {
      optLabel: 'Auctions',
      optValue: 'auctions',
    },
    {
      optLabel: 'Consumer Electronics & Computers Retail',
      optValue: 'consumer_electronics_and_computers_retail',
    },
    {
      optLabel: 'Apparel & Accessories Retail',
      optValue: 'apparel_and_accessories_retail',
    },
    {
      optLabel: 'Other Rental Stores (Furniture, A/V, Construction & Industrial Equipment)',
      optValue: 'other_rental_stores',
    },
    {
      optLabel: 'Flowers, Gifts & Specialty Stores',
      optValue: 'flowers_gifts_specialty_stores',
    },
  ],
  healthcare: [
    {
      optLabel: 'Hospitals & Clinics',
      optValue: 'hospitals_and_clinics',
    },
    {
      optLabel: 'Health Plan',
      optValue: 'health_plan',
    },
    {
      optLabel: 'Health Systems',
      optValue: 'health_systems',
    },
    {
      optLabel: 'Child Care',
      optValue: 'child_care',
    },
  ],
  professional_white_collar: [
    {
      optLabel: 'Business Services',
      optValue: 'business_services',
    },
    {
      optLabel: 'Consumer Services',
      optValue: 'consumer_services',
    },
    {
      optLabel: 'Insurance',
      optValue: 'insurance',
    },
    {
      optLabel: 'Banking & Finance',
      optValue: 'banking_and_finance',
    },
    {
      optLabel: 'Software',
      optValue: 'software',
    },
    {
      optLabel: 'IT',
      optValue: 'it',
    },
    {
      optLabel: 'Real Estate',
      optValue: 'real_estate',
    },
    {
      optLabel: 'Consulting',
      optValue: 'consulting',
    },
    {
      optLabel: 'Multimedia',
      optValue: 'multimedia',
    },
    {
      optLabel: 'Telecommunications',
      optValue: 'telecommunications',
    },
    {
      optLabel: 'Investment Banking',
      optValue: 'investment_banking',
    },
    {
      optLabel: 'Law / Legal',
      optValue: 'law_legal',
    },
    {
      optLabel: 'Hardware',
      optValue: 'hardware',
    },
    {
      optLabel: 'Education',
      optValue: 'education',
    },
  ],
  physical_blue_collar: [
    {
      optLabel: 'Manufacturing / Machinery',
      optValue: 'manufacturing_machinery',
    },
    {
      optLabel: 'Transportation & Freight',
      optValue: 'transportation_and_freight',
    },
    {
      optLabel: 'Automotive',
      optValue: 'automotive',
    },
    {
      optLabel: 'Computer',
      optValue: 'computer',
    },
    {
      optLabel: 'Medical Device',
      optValue: 'medical_device',
    },
    {
      optLabel: 'Aerospace',
      optValue: 'aerospace',
    },
    {
      optLabel: 'Industrial Equipment',
      optValue: 'industrial_equipment',
    },
    {
      optLabel: 'Electronics',
      optValue: 'electronics',
    },
    {
      optLabel: 'Construction',
      optValue: 'construction',
    },
    {
      optLabel: 'Home Goods',
      optValue: 'home_goods',
    },
    {
      optLabel: 'Food & Beverage',
      optValue: 'food_and_beverage',
    },
    {
      optLabel: 'Energy',
      optValue: 'energy',
    },
    {
      optLabel: 'Moving & Storage',
      optValue: 'moving_and_storage',
    },
    {
      optLabel: 'Mining',
      optValue: 'mining',
    },
    {
      optLabel: 'Home Services',
      optValue: 'home_services',
    },
    {
      optLabel: 'Auto Services',
      optValue: 'auto_services',
    },
  ],
  mixed_white_blue: [
    {
      optLabel: 'Business services / Energy',
      optValue: 'business_services_energy',
    },
    {
      optLabel: 'Business services / Food & Beverage',
      optValue: 'business_services_food_and_beverage',
    },
    {
      optLabel: 'Business services / Manufacturing',
      optValue: 'business_services_manufacturing',
    },
  ],
  agriculture: [
    {
      optLabel: 'Crops',
      optValue: 'crops',
    },
    {
      optLabel: 'Forestry',
      optValue: 'forestrye',
    },
    {
      optLabel: 'Animals & Livestock',
      optValue: 'animals_and_livestock',
    },
  ],
  business_services: [
    {
      optLabel: 'HR & Staffing',
      optValue: 'HR_and_staffing',
    },
    {
      optLabel: 'Security Products & Services',
      optValue: 'security_products_and_services',
    },
    {
      optLabel: 'Debt Collection',
      optValue: 'debt_collection',
    },
    {
      optLabel: 'Management Consulting',
      optValue: 'management_consulting',
    },
    {
      optLabel: 'Research & Development',
      optValue: 'research_and_development',
    },
    {
      optLabel: 'Information & Document Management',
      optValue: 'information_and_document_management',
    },
    {
      optLabel: 'Commercial Printing',
      optValue: 'commercial_printing',
    },
    {
      optLabel: 'Facilities Management & Commercial Cleaning',
      optValue: 'facilities_management_and_commercial_cleaning',
    },
    {
      optLabel: 'Food Service',
      optValue: 'food_service',
    },
    {
      optLabel: 'Advertising & Marketing',
      optValue: 'advertising_marketing',
    },
    {
      optLabel: 'Translation & Linguistic Services',
      optValue: 'translation_linguistic_services',
    },
    {
      optLabel: 'Accounting Services',
      optValue: 'accounting_services',
    },
    {
      optLabel: 'Multimedia & Graphic Design',
      optValue: 'multimedia_graphic_design',
    },
    {
      optLabel: 'Custom Software & IT Services',
      optValue: 'custom_software_and_it_services',
    },
    {
      optLabel: 'Call Centers & Business Centers',
      optValue: 'call_centers_and_business_centers',
    },
    {
      optLabel: 'Chambers of Commerce',
      optValue: 'chambers_of_commerce',
    },
  ],
  construction: [
    {
      optLabel: 'Civil Engineering Construction',
      optValue: 'civil_engineering_construction',
    },
    {
      optLabel: 'Architecture, Engineering & Design',
      optValue: 'architecture_engineering_and_design',
    },
    {
      optLabel: 'Commercial & Residential Construction',
      optValue: 'Commercial_and_residential_construction',
    },
  ],
  consumer_services: [
    {
      optLabel: 'Car & Truck Rental',
      optValue: 'car_and_truck_rental',
    },
    {
      optLabel: 'Weight & Health Management',
      optValue: 'weight_and_health_management',
    },
    {
      optLabel: 'Barber Shops & Beauty Salons',
      optValue: 'barber_shops_and_beauty_salons',
    },
    {
      optLabel: 'Funeral Homes & Funeral Related Services',
      optValue: 'funeral_homes_and_funeral_related_services',
    },
    {
      optLabel: 'Landscape Services',
      optValue: 'landscape_services',
    },
    {
      optLabel: 'Cleaning Services',
      optValue: 'cleaning_services',
    },
    {
      optLabel: 'Childcare',
      optValue: 'childcare',
    },
    {
      optLabel: 'Repair Services',
      optValue: 'repair_services',
    },
    {
      optLabel: 'Automotive Service & Collision Repair',
      optValue: 'automotive_service_and_collision_repair',
    },
    {
      optLabel: 'Photography Studio',
      optValue: 'photography_studio',
    },
  ],
  education: [
    {
      optLabel: 'Colleges & Universities',
      optValue: 'colleges_and_universities',
    },
    {
      optLabel: 'K-12 Schools',
      optValue: 'k12_schools',
    },
    {
      optLabel: 'Training',
      optValue: 'training',
    },
  ],
  energy_utilities_waste: [
    {
      optLabel: 'Water Treatment',
      optValue: 'water_treatment',
    },
    {
      optLabel: 'Electricity, Oil & Gas',
      optValue: 'electricity_oil_gas',
    },
    {
      optLabel: 'Waste Treatment, Environmental Services & Recycling',
      optValue: 'waste_treatment_environmental_services_recycling',
    },
    {
      optLabel: 'Oil & Gas Exploration & Services',
      optValue: 'oil_gas_exploration_services',
    },
  ],
  finance: [
    {
      optLabel: 'Banking',
      optValue: 'banking',
    },
    {
      optLabel: 'Venture Capital & Private Equity',
      optValue: 'venture_capital_private_equity',
    },
    {
      optLabel: 'Credit Cards & Transaction Processing',
      optValue: 'credit_cards_transaction_processing',
    },
    {
      optLabel: 'Lending & Brokerage',
      optValue: 'lending_brokerage',
    },
    {
      optLabel: 'Investment Banking',
      optValue: 'investment_banking',
    },
  ],
  government: [
    {
      optLabel: 'Local',
      optValue: 'local',
    },
    {
      optLabel: 'Federal',
      optValue: 'federal',
    },
    {
      optLabel: 'State',
      optValue: 'state',
    },
    {
      optLabel: 'Tribal Nations',
      optValue: 'tribal_nations',
    },
  ],
  healthcare_services: [
    {
      optLabel: 'Veterinary Services',
      optValue: 'veterinary_services',
    },
    {
      optLabel: 'Elderly Care Services',
      optValue: 'elderly_care_services',
    },
    {
      optLabel: 'Blood & Organ Banks',
      optValue: 'blood_organ_banks',
    },
    {
      optLabel: 'Mental Health & Rehabilitation Facilities',
      optValue: 'mental_health_rehabilitation_facilities',
    },
    {
      optLabel: 'Ambulance Services',
      optValue: 'ambulance_services',
    },
    {
      optLabel: 'Medical Laboratories & Imaging Centers',
      optValue: 'medical_laboratories_imaging_centers',
    },
  ],
  holding_companies_conglomerates: NO_VALUES,
  hospitality: [
    {
      optLabel: 'Zoos & National Parks',
      optValue: 'zoos_national_parks',
    },
    {
      optLabel: 'Fitness & Dance Facilities',
      optValue: 'fitness_dance_facilities',
    },
    {
      optLabel: 'Museums & Art Galleries',
      optValue: 'museums_art_galleries',
    },
    {
      optLabel: 'Libraries',
      optValue: 'libraries',
    },
    {
      optLabel: 'Sports Teams & Leagues',
      optValue: 'sports_teams_leagues',
    },
    {
      optLabel: 'Restaurants',
      optValue: 'restaurants',
    },
    {
      optLabel: 'Movie Theaters',
      optValue: 'movie_theaters',
    },
    {
      optLabel: 'Performing Arts Theaters',
      optValue: 'performing_arts_theaters',
    },
    {
      optLabel: 'Cultural & Informational Centers',
      optValue: 'cultural_informational_centers',
    },
    {
      optLabel: 'Amusement Parks, Arcades & Attractions',
      optValue: 'amusement_parks_arcades_attractions',
    },
    {
      optLabel: 'Gambling & Gaming',
      optValue: 'gambling_gaming',
    },
    {
      optLabel: 'Lodging & Resorts',
      optValue: 'lodging_resorts',
    },
    {
      optLabel: 'Travel Agencies & Services',
      optValue: 'travel_agencies_services',
    },
  ],
  hospitals_physicians_clinics: [
    {
      optLabel: 'Physicians Clinics',
      optValue: 'physicians_clinics',
    },
    {
      optLabel: 'Medical Specialists',
      optValue: 'medical_specialists',
    },
    {
      optLabel: 'Medical & Surgical Hospitals',
      optValue: 'medical_surgical_hospitals',
    },
    {
      optLabel: 'Dental Offices',
      optValue: 'dental_offices',
    },
  ],
  insurance: NO_VALUES,
  law_firms_legal_services: NO_VALUES,
  manufacturing: [
    {
      optLabel: 'Textiles & Apparel',
      optValue: 'textiles_apparel',
    },
    {
      optLabel: 'Motor Vehicles',
      optValue: 'motor_vehicles',
    },
    {
      optLabel: 'Industrial Machinery & Equipment',
      optValue: 'industrial_machinery_equipment',
    },
    {
      optLabel: 'Test & Measurement Equipment',
      optValue: 'test_measurement_equipment',
    },
    {
      optLabel: 'Computer Equipment & Peripherals',
      optValue: 'computer_equipment_peripherals',
    },
    {
      optLabel: 'Tires & Rubber',
      optValue: 'tires_rubber',
    },
    {
      optLabel: 'Pet Products',
      optValue: 'pet_products',
    },
    {
      optLabel: 'Medical Devices & Equipment',
      optValue: 'medical_devices_equipment',
    },
    {
      optLabel: 'Chemicals & Related Products',
      optValue: 'chemicals_related_products',
    },
    {
      optLabel: 'Pharmaceuticals',
      optValue: 'pharmaceuticals',
    },
    {
      optLabel: 'Appliances',
      optValue: 'appliances',
    },
    {
      optLabel: 'Aerospace & Defense',
      optValue: 'aerospace_defense',
    },
    {
      optLabel: 'Automotive Parts',
      optValue: 'automotive_parts',
    },
    {
      optLabel: 'Electronics',
      optValue: 'electronics',
    },
    {
      optLabel: 'Hand, Power & Lawn-care Tools',
      optValue: 'hand_power_lawncare_tools',
    },
    {
      optLabel: 'Cleaning Products',
      optValue: 'cleaning_products',
    },
    {
      optLabel: 'Boats & Submarines',
      optValue: 'boats_submarines',
    },
    {
      optLabel: 'Photographic & Optical Equipment',
      optValue: 'photographic_optical_equipment',
    },
    {
      optLabel: 'Watches & Jewelry',
      optValue: 'watches_jewelry',
    },
    {
      optLabel: 'Plastic, Packaging & Containers',
      optValue: 'plastic_packaging_containers',
    },
    {
      optLabel: 'Cosmetics, Beauty Supply & Personal Care Products',
      optValue: 'cosmetics_beauty_supply_personal_care_products',
    },
    {
      optLabel: 'Wire & Cable',
      optValue: 'wire_cable',
    },
    {
      optLabel: 'Glass & Clay',
      optValue: 'glass_clay',
    },
    {
      optLabel: 'Household Goods',
      optValue: 'household_goods',
    },
    {
      optLabel: 'Toys & Games',
      optValue: 'toys_games',
    },
    {
      optLabel: 'Furniture',
      optValue: 'furniture',
    },
    {
      optLabel: 'Pulp & Paper',
      optValue: 'pulp_paper',
    },
    {
      optLabel: 'Building Materials',
      optValue: 'building_materials',
    },
    {
      optLabel: 'Health & Nutrition Products',
      optValue: 'health_nutrition_products',
    },
    {
      optLabel: 'Sporting Goods',
      optValue: 'sporting_goods',
    },
    {
      optLabel: 'Telecommunication Equipment',
      optValue: 'telecommunication_equipment',
    },
    {
      optLabel: 'Food & Beverage',
      optValue: 'food_and_beverage',
    },
  ],
  media_internet: [
    {
      optLabel: 'Broadcasting',
      optValue: 'broadcasting',
    },
    {
      optLabel: 'Publishing',
      optValue: 'publishing',
    },
    {
      optLabel: 'Social Networks',
      optValue: 'social_networks',
    },
    {
      optLabel: 'Newspapers & News Services',
      optValue: 'newspapers_news_services',
    },
    {
      optLabel: 'Data Collection & Internet Portals',
      optValue: 'data_collection_internet_portals',
    },
    {
      optLabel: 'Ticket Sales',
      optValue: 'ticket_sales',
    },
    {
      optLabel: 'Music Production & Services',
      optValue: 'music_production_services',
    },
  ],
  minerals_mining: NO_VALUES,
  organizations: [
    {
      optLabel: 'Non-Profit & Charitable Organizations',
      optValue: 'non_profit_charitable_organizations',
    },
    {
      optLabel: 'Religious Organizations',
      optValue: 'religious_organizations',
    },
    {
      optLabel: 'Membership Organizations',
      optValue: 'membership_organizations',
    },
  ],
  real_estate: NO_VALUES,
  software: [
    {
      optLabel: 'Supply Chain Management (SCM) Software',
      optValue: 'supply_chain_management_software',
    },
    {
      optLabel: 'Mobile App Development',
      optValue: 'mobile_app_developmente',
    },
    {
      optLabel: 'Business Intelligence (BI) Software',
      optValue: 'business_intelligence_software',
    },
    {
      optLabel: 'Legal Software',
      optValue: 'legal_software',
    },
    {
      optLabel: 'Customer Relationship Management (CRM) Software',
      optValue: 'customer_relationship_management_software',
    },
    {
      optLabel: 'Engineering Software',
      optValue: 'engineering_software',
    },
    {
      optLabel: 'Networking Software',
      optValue: 'networking_software',
    },
    {
      optLabel: 'Storage & System Management Software',
      optValue: 'storage_system_management_software',
    },
    {
      optLabel: 'Human Resources Software',
      optValue: 'human_resources_software',
    },
    {
      optLabel: 'Financial Software',
      optValue: 'financial_software',
    },
    {
      optLabel: 'Database & File Management Software',
      optValue: 'database_file_management_software',
    },
    {
      optLabel: 'Security Software',
      optValue: 'security_software',
    },
    {
      optLabel: 'Enterprise Resource Planning (ERP) Software',
      optValue: 'enterprise_resource_planning_software',
    },
    {
      optLabel: 'Multimedia, Games & Graphics Software',
      optValue: 'multimedia_games_graphics_software',
    },
    {
      optLabel: 'Content & Collaboration Software',
      optValue: 'content_collaboration_software',
    },
    {
      optLabel: 'Healthcare Software',
      optValue: 'healthcare_software',
    },
  ],
  telecommunications: [
    {
      optLabel: 'Cable & Satellite',
      optValue: 'cable_and_satellite',
    },
    {
      optLabel: 'Internet Service Providers, Website Hosting & Internet-related Services',
      optValue: 'internet_service_providers',
    },
    {
      optLabel: 'Telephony & Wireless',
      optValue: 'telephony_and_wireless',
    },
  ],
  transportation: [
    {
      optLabel: 'Trucking, Moving & Storage',
      optValue: 'trucking_moving_and_storage',
    },
    {
      optLabel: 'Airlines, Airports & Air Services',
      optValue: 'airlines_airports_air_Services',
    },
    {
      optLabel: 'Marine Shipping & Transportation',
      optValue: 'marine_shipping_and_transportation',
    },
    {
      optLabel: 'Rail, Bus & Taxi',
      optValue: 'rail_bus_and_taxi',
    },
    {
      optLabel: 'Freight & Logistics Services',
      optValue: 'freight_and_logistics_services',
    },
  ],
};

export const InstitutionCategory = [
  {
    optLabel: 'Commercial',
    optValue: 'commercial',
  }, {
    optLabel: 'Non-commercial',
    optValue: 'non-commercial',
  },
  {
    optLabel: 'Provider',
    optValue: 'provider',
  },
];

export const DptOnboarding = [
  {
    optLabel: 'After condition',
    optValue: 'after_condition',
  },
  {
    optLabel: 'After kit info',
    optValue: 'after_kit_info',
  },
  {
    optLabel: 'Enrollment finish',
    optValue: 'enrollment_finish',
  },
];

export const MoveOnboarding = [
  {
    optLabel: 'After kit info',
    optValue: 'after_kit_info',
  },
  {
    optLabel: 'Enrollment finish',
    optValue: 'enrollment_finish',
  },
];

export const InstitutionType = [
  {
    optLabel: 'Pilot',
    optValue: 'pilot',
  }, {
    optLabel: 'Non-pilot',
    optValue: 'non-pilot',
  },
];

export const EligibilityFields = [
  {
    key: 'first_name',
    label: 'First Name',
  },
  {
    key: 'last_name',
    label: 'Last Name',
  },
  {
    key: 'date_of_birth',
    label: 'Date of Birth',
  },
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'member_id',
    label: 'Member ID',
  },
  {
    key: 'external_id',
    label: 'External ID',
  },
  {
    key: 'employee_id',
    label: 'Employee ID',
  },
  {
    key: 'aso_group',
    label: 'ASO Group',
  },
  {
    key: 'program_type',
    label: 'Program Type',
  },
  {
    key: 'ecosystem_external_id',
    label: 'Ecosystem External ID',
  },
  {
    key: 'address',
    label: 'Address',
  },
  {
    key: 'address2',
    label: 'Address 2',
  },
  {
    key: 'city',
    label: 'City',
  },
  {
    key: 'state',
    label: 'State',
  },
  {
    key: 'country',
    label: 'Country',
  },
  {
    key: 'zip_code',
    label: 'Zip Code',
  },
  {
    key: 'phone',
    label: 'Phone',
  },
  {
    key: 'gender',
    label: 'Gender',
  },
  {
    key: 'dependent_relationship',
    label: 'Relationship',
  },
  {
    key: 'ssn',
    label: 'SSN',
  },
  {
    key: 'group_policy_id',
    label: 'Group Policy ID',
  },
  {
    key: 'group_policy_name',
    label: 'Group Policy Name',
  },
  {
    key: 'unique_id',
    label: 'Unique ID',
  },
  {
    key: 'dependent_relation_value',
    label: 'Dependent Relation Value',
  },
  {
    key: 'subscriber_id',
    label: 'Subscriber ID',
  },
  {
    key: 'do_not_contact',
    label: 'Do Not Contact',
  },
  {
    key: 'employee_start_date',
    label: 'Employee Start Date',
  },
  {
    key: 'employee_term_date',
    label: 'Employee Term Date',
  },
  {
    key: 'health_coverage',
    label: 'Health Coverage',
  },
  {
    key: 'health_coverage_start_date',
    label: 'Health Coverage Start Date',
  },
  {
    key: 'health_coverage_end_date',
    label: 'Health Coverage End Date',
  },
  {
    key: 'employer_business_unit',
    label: 'Employer Business Unit',
  },
  {
    key: 'plan_type',
    label: 'Plan Type',
  },
  {
    key: 'payer',
    label: 'Payer',
  },
  {
    key: 'service_type',
    label: 'Service Type',
  },
];

export const eligibilityCriteriaOptions = [
  {
    value: 'employees_spouses_and_dependents',
    text: 'Employees, spouses and dependents',
  },
  {
    value: 'employee_and_spouse',
    text: 'Employee and spouse',
  },
  {
    value: 'employee',
    text: 'Employee',
  },
  {
    value: 'other',
    text: 'Other',
  },
];

export const implementationStatusOptions = [
  {
    value: 'opportunity',
    text: 'Opportunity',
  },
  {
    value: 'in_implementation',
    text: 'In Implementation',
  },
  {
    value: 'in_termination',
    text: 'In Termination',
  },
  {
    value: 'ready',
    text: 'Ready',
  },
];

export const benefitsEnrolledEligibleOptions = [
  {
    value: 'benefits_enrolled_eligible',
    text: 'Benefits enrolled/eligible',
  },
  {
    value: 'benefits_enrolled',
    text: 'Benefits enrolled',
  },
  {
    value: 'benefits_eligible',
    text: 'Benefits eligible',
  },
  {
    value: 'benefits_eligible_employees_enrolled_dependents',
    text: 'Benefits eligible employees/enrolled dependents',
  },
];

export const memberPaymentOptions = [
  {
    value: 'no_cost',
    text: 'No cost',
  },
  {
    value: 'additional_cost_in_eligibility_statement',
    text: 'Additional cost in eligibility statement',
  },
];

export const channelOptInOptions = [
  {
    value: 'allow_direct_mailer',
    text: 'Allow direct mailer',
  },
  {
    value: 'email',
    text: 'Email',
  },
  {
    value: 'sms',
    text: 'SMS',
  },
];

export const d2cOptions = [
  { value: D2C_OPTIONS.DISABLED, text: 'Disabled' },
  { value: D2C_OPTIONS.BECOME, text: 'Become' },
  { value: D2C_OPTIONS.BLOSSOM, text: 'Blossom' },
];
